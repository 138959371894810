var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"send-money-promo-code col-span-1"},[(_vm.activePromotion)?_c('div',{class:{
      summary: _vm.activeStepIsSummary,
      'promo-code-content-wrapper': true,
      'input-input': true,
      'promo-active': true,
    }},[_c('div',{staticClass:"promo-code-content flex-col"},[_c('div',{staticClass:"promo-text-wrapper flex"},[_c('AppIcon',{staticClass:"icon-gift",attrs:{"name":" "}},[_c('IconGift')],1),_c('p',{staticClass:"promo-code-text",domProps:{"innerHTML":_vm._s(_vm.promotionText)}})],1),_c('div',{staticClass:"promo-details flex items-center cursor-pointer",on:{"click":_vm.showRewardDetailsModal}},[_c('IconInfoCircleGray',{staticClass:"icon-tooltip"}),_c('p',{staticClass:"promo-code-text font-semibold"},[_vm._v(" "+_vm._s(_vm.$t('SendMoneyAmount.ReferAFriend.PromoCode.RewardDetails').value)+" ")])],1)]),(!_vm.activeStepIsSummary)?_c('XeBackButton',{attrs:{"icon":"x"},on:{"click":_vm.removePromo}}):_vm._e()],1):_c('div',{class:{
      disabled: _vm.promoDisabled,
      'promo-code-content-wrapper': true,
      'input-input cursor-pointer': true,
    },on:{"click":_vm.showPromoCodeModal}},[_c('div',{staticClass:"promo-code-content"},[_c('AppIcon',{staticClass:"icon-gift",attrs:{"name":" "}},[_c('IconGift')],1),_c('p',{staticClass:"promo-code-text"},[_vm._v(" "+_vm._s(_vm.$t('SendMoneyAmount.ReferAFriend.PromoCode.Title').value)+" ")])],1),_c('AppIcon',{attrs:{"name":" "}},[_c('IconChevronRight')],1)],1),_c('SendMoneyRewardDetailsModal',{on:{"close":_vm.closeRewardDetailsModal},model:{value:(_vm.rewardDetailsModalOpened),callback:function ($$v) {_vm.rewardDetailsModalOpened=$$v},expression:"rewardDetailsModalOpened"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }