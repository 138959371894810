<template>
  <div class="send-money-promo-code col-span-1">
    <div
      v-if="activePromotion"
      :class="{
        summary: activeStepIsSummary,
        'promo-code-content-wrapper': true,
        'input-input': true,
        'promo-active': true,
      }"
    >
      <div class="promo-code-content flex-col">
        <div class="promo-text-wrapper flex">
          <AppIcon name=" " class="icon-gift">
            <IconGift />
          </AppIcon>
          <p class="promo-code-text" v-html="promotionText" />
        </div>
        <div @click="showRewardDetailsModal" class="promo-details flex items-center cursor-pointer">
          <IconInfoCircleGray class="icon-tooltip" />
          <p class="promo-code-text font-semibold">
            {{ $t('SendMoneyAmount.ReferAFriend.PromoCode.RewardDetails').value }}
          </p>
        </div>
      </div>
      <XeBackButton v-if="!activeStepIsSummary" icon="x" @click="removePromo" />
    </div>

    <div
      v-else
      :class="{
        disabled: promoDisabled,
        'promo-code-content-wrapper': true,
        'input-input cursor-pointer': true,
      }"
      @click="showPromoCodeModal"
    >
      <div class="promo-code-content">
        <AppIcon name=" " class="icon-gift">
          <IconGift />
        </AppIcon>
        <p class="promo-code-text">
          {{ $t('SendMoneyAmount.ReferAFriend.PromoCode.Title').value }}
        </p>
      </div>
      <AppIcon name=" ">
        <IconChevronRight />
      </AppIcon>
    </div>
    <SendMoneyRewardDetailsModal
      v-model="rewardDetailsModalOpened"
      @close="closeRewardDetailsModal"
    />
  </div>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import { storeToRefs } from 'pinia'
import {
  useI18nStore,
  usePromotionStore,
  useSendMoneyStore,
  useAnalyticsStore,
} from '@galileo/stores'
import { useRouter } from '@galileo/composables/useRouter'
import XeBackButton from '@galileo/components/XeBackButton/XeBackButton'
import { AppIcon } from '@oen.web.vue2/ui'
import { IconGift, IconChevronRight } from '@oen.web.vue2/icons'
import { IconInfoCircleGray } from '@galileo/assets/icons/vue'
import SendMoneyRewardDetailsModal from '@galileo/components/Views/SendMoney/Modals/SendMoneyRewardDetailsModal'
import { SEGMENT_EVENTS } from '@galileo/constants/segmentAnalytics'
import { PROMOTIONS_TYPE } from '@galileo/constants/promotions.const'

export default {
  name: 'SendMoneyPromoCode',
  components: {
    XeBackButton,
    AppIcon,
    IconGift,
    IconInfoCircleGray,
    IconChevronRight,
    SendMoneyRewardDetailsModal,
  },
  props: {
    promoDisabled: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { $t } = useI18nStore()
    const router = useRouter()
    const promotionStore = usePromotionStore()
    const sendMoneyStore = useSendMoneyStore()
    const analyticsStore = useAnalyticsStore()

    const { activePromotion } = storeToRefs(promotionStore)
    const rewardDetailsModalOpened = ref(false)

    const activeStepIsSummary = computed(
      () => sendMoneyStore.activeStep.path === '/send-money/summary'
    )

    const showPromoCodeModal = () => {
      analyticsStore.track({
        event: SEGMENT_EVENTS.PROMOTIONS_ACCESSED,
        traits: {
          promosAvailable: promotionStore.promotionsNames,
          loggedIn: true,
        },
      })

      router.push({
        name: 'SendMoneyPromoCodeModal',
        append: true,
      })
    }

    const showRewardDetailsModal = () => {
      rewardDetailsModalOpened.value = true
    }

    const closeRewardDetailsModal = () => {
      rewardDetailsModalOpened.value = false
    }

    const promotionText = computed(() => {
      const activePromotionData = promotionStore.getActivePromoData()
      const promoText = ref('')

      if (activePromotionData.name == PROMOTIONS_TYPE.REFER_FRIEND) {
        promoText.value = $t('SendMoneyAmount.ReferAFriend.PromoCode.PromoText', {
          currency: activePromotionData.data.symbol,
          qualifyingAmount: activePromotionData.data.qualifyingAmount,
          giftCardAmount: activePromotionData.data.giftCardAmount,
        }).value
      } else {
        promoText.value = $t('SendMoneyAmount.ReferAFriend.OtherPromoCode.PromoText', {
          currency: activePromotionData.data.symbol,
          qualifyingAmount: activePromotionData.data.qualifyingAmount,
          giftCardAmount: activePromotionData.data.giftCardAmount,
        }).value
      }

      return promoText.value
    })

    const removePromo = async () => {
      const promotionsNames = promotionStore.promotionsNames

      if (activePromotion.value) {
        analyticsStore.track({
          event: SEGMENT_EVENTS.PROMOTION_REMOVED,
          traits: {
            promoCode: activePromotion.value.promotionCode.code,
            promoName: activePromotion.value.name,
            promosAvailable: promotionsNames,
          },
        })
      }

      promotionStore.resetState()
      await sendMoneyStore.calculate({ summary: false, debounceTime: 0 })
    }

    return {
      $t,
      activeStepIsSummary,
      rewardDetailsModalOpened,
      showPromoCodeModal,
      showRewardDetailsModal,
      closeRewardDetailsModal,
      removePromo,
      promotionText,
      activePromotion,
    }
  },
}
</script>

<style scoped>
.send-money-promo-code {
  .promo-code-content-wrapper {
    @apply relative flex items-center justify-between w-full p-3 rounded-lg mb-6;
    &.summary {
      @apply mt-6;
    }
    &.promo-active {
      @apply items-start h-auto;
      .promo-code-content {
        @apply items-start;
      }
    }

    .promo-code-content {
      @apply flex items-center;
    }

    .promo-text-wrapper svg {
      min-width: 24px;
    }

    .promo-details {
      @apply mt-1.5;
      .icon-tooltip {
        margin: 0 0.175rem;
        background-image: linear-gradient(to right, #333 20%, rgba(0, 0, 0, 0) 20%);
        background-position: 7px;
        background-size: 20px 10px;
        background-repeat: no-repeat;
      }
    }

    .icon-gift {
      @apply mb-0.5;
      color: var(--theme-color-primary, rgba(0, 108, 224, 1)) !important;
    }

    .promo-code-text {
      @apply ml-1.5 pr-3;
    }

    .spinner {
      @apply absolute top-1/2 left-1/2;
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  @media (max-width: 600px) {
    .promo-code-content-wrapper {
      .button {
        @apply w-6 h-6;
      }

      ::v-deep .button.button--icon .button-inner {
        @apply w-full h-full;
      }
    }
  }
}
</style>
