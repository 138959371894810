<template>
  <AppCard class="p-4">
    <template #header>
      <AppCardImageTitle
        :alt="$t('AccountRestricted.UnableToSendMoneyAlt').value"
        :title="$t('VerificationOutcomeModal.Closed.Title').value"
        :src="require(`@galileo/assets/images/illustrations/lock.svg`)"
      />
    </template>
    <p class="text-secondary-text text-center">
      {{ $t('VerificationOutcomeModal.Closed.Text').value }}
    </p>
    <template #footer>
      <AppCardFooter class="button-footer">
        <AppButton @click="onCancelClick">
          {{ $t('VerificationOutcomeModal.Closed.PrimaryButton').value }}
        </AppButton>
        <AppButton theme="secondary" @click="onVisitHelpCenterClick">
          {{ $t('VerificationOutcomeModal.Closed.SecondaryButton').value }}
        </AppButton>
      </AppCardFooter>
    </template>
  </AppCard>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import { useRouter } from '@galileo/composables/useRouter'

import { AppCard, AppCardImageTitle, AppCardFooter, AppButton } from '@oen.web.vue2/ui'


import { useI18nStore, useAppStore } from '@galileo/stores'


export default defineComponent({
  components: {
    AppCard,
    AppCardImageTitle,
    AppCardFooter,
    AppButton,
  },
  setup() {
    const { $t } = useI18nStore()
    const router = useRouter()
    const appStore = useAppStore()

    const onCancelClick = () => {
      router.replace('/activity')
    }
    const loadingHelpDesk = ref(false)
    const onVisitHelpCenterClick = () => {
      appStore.openHelpDesk({ loadingRef: loadingHelpDesk })
    }
    return {
      $t,
      onCancelClick,
      onVisitHelpCenterClick,
    }
  },
})
</script>

<style scoped>
.button-footer {
  @apply flex flex-col-reverse justify-around;
}
.button-footer > button {
  @apply my-2;
}
</style>
